module.exports = {
  selectLanguage: "Select your language",
  "index.textBy": `Text by {author}`,
  "index.handwritingBy": `Handwriting by {interpreterName}`,
  "artist.teresa.name": `Teresa Feldmann`,
  "artist.teresa.role": `Artist/Designer`,
  "artist.uchida.name": `Seira Uchida`,
  "artist.uchida.role": `Internet Bender`,
  "statement.description": `In light of the online Artist-in-Residence program "Liminal Space" (2021), artist Seira Uchida (Japan) and artist/designer Teresa Feldmann (Netherlands) rethought about "family" and "care." The individuals who also had been involved with both artists contributed texts and writers from around the world through crowdsourcing rewrote them eventually. With the ongoing paradigm shift that surfaced with the pandemic, the voices of those who sustain the world and their families in the crowd merge in the liminal space.`,
  "menu.about": "About",
  "menu.links": "Links",
};
